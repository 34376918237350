<template>
  <div class="insure">
    <div class="applicantContent">
      <!-- 投保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">1</div>
        <div class="titleName">投保人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            maxlength="20"
            v-model="fromData.applicant.name"
            :onkeyup="
              (fromData.applicant.name = fromData.applicant.name.replace(
                /\s+/g,
                ''
              ))
            "
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">证件类型</div>
          <span class="uni-input">身份证</span>
        </div>

        <div class="uni-form-item">
          <div class="tit">证件号码</div>
          <input
            class="uni-input"
            placeholder="点击输入证件号码"
            v-model="fromData.applicant.certNo"
            :onkeyup="
              (fromData.applicant.certNo = fromData.applicant.certNo.replace(
                /\s+/g,
                ''
              ))
            "
            @input="wathcApplicantCertNo"
            maxlength="18"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">手机号</div>
          <input
            class="uni-input"
            placeholder="点击输入手机号码"
            v-model="fromData.applicant.mobile"
            :onkeyup="
              (fromData.applicant.mobile = fromData.applicant.mobile.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="11"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">性别</div>
          <div class="uni-input" @click="changeSexApplicant">
            <div :class="sexApplicantValue ? 'input' : 'inputno'">
              {{ sexApplicantValue ? sexApplicantValue : "选择性别" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderSexApplicant"
            isShowStr="holderSexApplicant"
            @callback="callbackSexApplicant"
            :columns="sexList"
          />
          <img
            class="arrow"
            src="../../static/arrow-icon.png"
            mode="widthFix"
          />
        </div>
      </div>
      <!-- 被保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">2</div>
        <div class="titleName">被监护人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            maxlength="20"
            v-model="insuredFrom.name"
            :onkeyup="(insuredFrom.name = insuredFrom.name.replace(/\s+/g, ''))"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">出生日期</div>
          <div class="uni-input" @click="isInsuredBirthaday = true">
            <div :class="insuredFrom.birthday ? 'input' : 'inputno'">
              {{ insuredFrom.birthday ? insuredFrom.birthday : "选择出生日期" }}
            </div>
          </div>
          <DatePiker
            :isShow="isInsuredBirthaday"
            :typeDate="'insuredBirthaday'"
            @cancelDate="cancelDate"
            @callbackDate="callbackCertStartDate"
          />
          <img
            class="arrow"
            src="../../static/arrow-icon.png"
            mode="widthFix"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">性别</div>
          <div class="uni-input" @click="changeSexInsured">
            <div :class="sexInsuredValue ? 'input' : 'inputno'">
              {{ sexInsuredValue ? sexInsuredValue : "选择性别" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderSexInsured"
            isShowStr="holderSexInsured"
            @callback="callbackSexInsured"
            :columns="sexList"
          />
          <img
            class="arrow"
            src="../../static/arrow-icon.png"
            mode="widthFix"
          />
        </div>
      </div>
      <!-- 保障计划 -->
      <div class="applicant-title">
        <div class="titleNum">3</div>
        <div class="titleName">保障计划</div>
      </div>
      <div class="responsibility">
        <div class="title">
          <div
            :class="
              responsibilityActive == index
                ? 'responsibility-bg-active item'
                : 'responsibility-bg item'
            "
            v-for="(item, index) in responsibilityData"
            :key="index"
            @click="changeResponsibility(index, item)"
          >
            <p
              :class="
                responsibilityActive == index
                  ? 'responsibilityColor-active'
                  : 'responsibilityColor'
              "
            >
              <span>{{ item.securityPlanName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="insure-date">
        <div class="item">
          <p>生效日期</p>
          <p>{{ todayDate }}</p>
        </div>
        <div class="item">
          <p>终止日期</p>
          <p>{{ futureDate }}</p>
        </div>
        <div class="item">
          <p>保障期间</p>
          <p>{{ indate }}年</p>
        </div>
      </div>
      <!-- 选择医院 -->
      <div class="applicant-title">
        <div class="titleNum">4</div>
        <div class="titleName">选择医院</div>
      </div>
      <div>
        <van-field
          readonly
          clickable
          label="医院"
          :value="selectedHospitalItem ? selectedHospitalItem.name : ''"
          placeholder="请选择"
          @click="onShowHospitalPicker"
        >
          <template slot="right-icon">
            <img
              class="arrow"
              src="../../static/arrow-icon.png"
              mode="widthFix"
              width="10"
            />
          </template>
        </van-field>
      </div>
    </div>
    <div class="footer">
      <div class="file">
        <div @click="checkFile = !checkFile">
          <img
            v-if="checkFile"
            src="../../../../assets/file-check-icon.png"
            alt=""
          />
          <img v-else src="../../../../assets/file-icon.png" alt="" />
        </div>

        <p>我已阅读</p>
        <div class="list">
          <p @click="insuranceNotice">投保须知</p>
          <p @click="fileList('productTerms')">保险条款</p>
          <p @click="fileList('rateTable')">费率表</p>
          <p @click="customerNotification">客户告知</p>
        </div>
      </div>
      <div class="handle">
        <div class="price">
          价格：¥
          <div class="num">{{ sumPremium ? sumPremium : "--" }}</div>
        </div>
        <div class="btn" @click="rbInsure">立即投保</div>
      </div>
    </div>
    <van-popup v-model="showHospitalPicker" round position="bottom">
      <van-picker
        ref="hospitalPicker"
        title="请选择"
        show-toolbar
        :columns="hospitals"
        :loading="hospitalLoading"
        value-key="name"
        @cancel="showHospitalPicker = false"
        @confirm="onConfirmHospital"
      />
    </van-popup>
  </div>
</template>
<script>
import CommonPiker from "../../components/commonPiker.vue";
import DatePiker from "../../components/datePiker.vue";
import { sexList } from "../../../../assets/js/common";
import { insure, queryHospitals } from "../../../../utils/api";
import { validIdCard, validPhone } from "../../../../utils/validate";
import moment from "moment";
export default {
  components: {
    CommonPiker,
    DatePiker,
  },
  data() {
    return {
      fromData: {
        productId: "",
        productName: "",
        subInsuranceCompanyId: "",
        subInsuranceCompanyName: "",
        planId: "", // 保障计划id
        planName: "", // 保障计划名称
        risks: [],
        applicant: {
          //【投保人信息】
          name: "", //姓名
          certNo: "", //证件号码
          sex: "",
          mobile: "", // 手机号
        },
        insureds: [],
        inputFactors: {
          DutyCode: "",
          choiceType: 1,
          inoculationPoint: sessionStorage.getItem("cityName"),
        },
      },
      insuredFrom: {
        //【被保人信息】
        name: "", //姓名
        sex: "",
        birthday: "",
      },

      holderRelationship: false,
      productDetail: {}, //产品详情
      planActive: 0, //选中计划index
      relationshipValue: "", //关系值
      responsibilityData: [],
      responsibilityActive: 0,

      sexList: sexList, //性别类型的data
      sexApplicantValue: "", //投保人性别值
      holderSexApplicant: false, //投保人性别弹框
      nationalityApplicantValue: "", //投保人证件类型值
      holderNationalityApplicant: false, //投保人证件类型弹框
      sexInsuredValue: "", //被保人性别值
      holderSexInsured: false, //被保人性别弹框

      checkFile: false, //是否已经阅读相关文件
      riskData: [], //险种数据
      totalPrem: "", //总保费
      riskAmnt: "", //试算保额
      isFirstEnter: false,
      todayDate: "", //当前日期
      futureDate: "- -", //当前日期一年后的日期
      insuredAge: "", // 被保人年龄
      // 保障计划数据
      plans: [{}],
      sumAmount: "", // 保额
      sumPremium: "", // 保费
      indate: "- -", // 保障期间
      endDate: "",
      isXhrSend: true, // 是否调用后端发投保接口默认为调用
      isInsuredBirthaday: false, // 被保人日期弹窗
      isDisabled: true,
      // 选择医院
      showHospitalPicker: false,
      hospitals: [],
      hospitalLoading: false,
      selectedHospitalItem: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "confirm" ||
      from.name == "confirm" ||
      from.name == "fileList" ||
      from.name == "pdf" ||
      from.name == "insuranceNotice" ||
      from.name == "rightsAndInterests"
    ) {
      to.meta.isBack = true;
    }
    next();
  },
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },

  async mounted() {
    this.responsibilityData = JSON.parse(sessionStorage.getItem("planData"));
    this.planId = this.responsibilityData[0].id;
    this.planName = this.responsibilityData[0].securityPlanName;
    //获取当前日期
    this.todayDate = moment(
      new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    ).format("YYYY年MM月DD日");
    // 获取 *年后的时间
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    this.$toast.clear();
  },
  methods: {
    //回调日期
    callbackCertStartDate(type, value) {
      if (type == "insuredBirthaday") {
        //被保人生日
        this.isInsuredBirthaday = false;
        this.insuredFrom.birthday = value;
        this.changeResponsibility(this.responsibilityActive);
        this.getLastDate(this.indate);
      }
    },
    //关闭日期弹窗
    cancelDate(type) {
      if (type == "insuredBirthaday") {
        //被保人生日
        this.isInsuredBirthaday = false;
      }
    },
    // 获取年龄区间
    getAge(brthday, index) {
      // 传进来的身份证生日
      var brthday = brthday;
      // 获取现在日期的年月日
      const nowDate = new Date();
      var nowYear = nowDate.getFullYear();
      var nowMonth = nowDate.getMonth() + 1;
      var nowDay = nowDate.getDate();
      // 截取生日中的年月日
      var brthdayYear = parseInt(brthday.split("-")[0]);
      var brthdayMonth = parseInt(brthday.split("-")[1]);
      var brthdayDay = parseInt(brthday.split("-")[2]);
      // 现在日期减去生日中的日期
      var day = nowDay - brthdayDay;
      var month = nowMonth - brthdayMonth;
      var year = nowYear - brthdayYear;
      nowDate.setDate(0);
      if (day < 0) {
        month -= 1;
        day = day + nowDate.getDate(); //得到该月天数
      }
      if (month < 0) {
        year -= 1;
        month = month + 12;
      }
      if (year > 0 && month == 12) {
        year += 1;
      }
      var months = year * 12 + month;
      if (index == 0) {
        this.sumAmount = "755000.00";
        if (
          (months == 0 && day >= 0) ||
          (months > 0 && months < 24) ||
          (months == 24 && day == 0)
        ) {
          // 0-2岁 包含0岁和2岁
          this.plans = [
            {
              planCode: "ZKF110001s",
            },
          ];
          this.sumPremium = "300.00";
          this.indate = "6"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 24 && day > 0) ||
          (months > 24 && months < 36) ||
          (months == 36 && day == 0)
        ) {
          // 2-3岁 包含3岁
          this.plans = [
            {
              planCode: "ZKF110001v",
            },
          ];
          this.sumPremium = "258.00";
          this.indate = "5"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 36 && day > 0) ||
          (months > 36 && months < 48) ||
          (months == 48 && day == 0)
        ) {
          //3-4岁 包含4岁
          this.plans = [
            {
              planCode: "ZKF110001y",
            },
          ];
          this.sumPremium = "208.00";
          this.indate = "4"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 48 && day > 0) ||
          (months > 48 && months < 60) ||
          (months == 60 && day == 0)
        ) {
          // 4-5岁 包含5岁
          this.plans = [
            {
              planCode: "ZKF110002b",
            },
          ];
          this.sumPremium = "180.00";
          this.indate = "3"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 60 && day > 0) ||
          (months > 60 && months < 72) ||
          (months == 72 && day == 0)
        ) {
          // 5-岁 包含6岁
          this.plans = [
            {
              planCode: "ZKF110002e",
            },
          ];
          this.sumPremium = "120.00";
          this.indate = "2"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 72 && day > 0) ||
          (months > 72 && months < 156) ||
          (months == 156 && day == 0)
        ) {
          // 6-13岁 包含13岁
          this.plans = [
            {
              planCode: "ZKF110002h",
            },
          ];
          this.sumPremium = "60.00";
          this.indate = "1"; // 保障期限
          this.isXhrSend = true;
        } else {
          // 小于0岁 或 大于13 超出范围
          this.$toast("被监护人年龄超出范围");
          this.isXhrSend = false;
        }
        this.plans[0].groupNo = "1";
      } else if (index == 1) {
        this.sumAmount = "1240000.00";
        if (
          (months == 0 && day >= 0) ||
          (months > 0 && months < 24) ||
          (months == 24 && day == 0)
        ) {
          // 0-2岁 包含0岁和2岁
          this.plans = [
            {
              planCode: "ZKF110001t",
            },
          ];
          this.sumPremium = "500.00";
          this.indate = "6"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 24 && day > 0) ||
          (months > 24 && months < 36) ||
          (months == 36 && day == 0)
        ) {
          // 2-3岁 包含3岁
          this.plans = [
            {
              planCode: "ZKF110001w",
            },
          ];
          this.sumPremium = "418.00";
          this.indate = "5"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 36 && day > 0) ||
          (months > 36 && months < 48) ||
          (months == 48 && day == 0)
        ) {
          //3-4岁 包含4岁
          this.plans = [
            {
              planCode: "ZKF110001z",
            },
          ];
          this.sumPremium = "338.00";
          this.indate = "4"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 48 && day > 0) ||
          (months > 48 && months < 60) ||
          (months == 60 && day == 0)
        ) {
          // 4-5岁 包含5岁
          this.plans = [
            {
              planCode: "ZKF110002c",
            },
          ];
          this.sumPremium = "300.00";
          this.indate = "3"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 60 && day > 0) ||
          (months > 60 && months < 72) ||
          (months == 72 && day == 0)
        ) {
          // 5-岁 包含6岁
          this.plans = [
            {
              planCode: "ZKF110002f",
            },
          ];
          this.sumPremium = "200.00";
          this.indate = "2"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 72 && day > 0) ||
          (months > 72 && months < 156) ||
          (months == 156 && day == 0)
        ) {
          // 6-13岁 包含13岁
          this.plans = [
            {
              planCode: "ZKF110002i",
            },
          ];
          this.sumPremium = "100.00";
          this.indate = "1"; // 保障期限
          this.isXhrSend = true;
        } else {
          // 小于0岁 或 大于13 超出范围
          this.$toast("被监护人年龄超出范围");
          this.isXhrSend = false;
        }
        this.plans[0].groupNo = "2";
      } else {
        this.sumAmount = "2320000.00";
        if (
          (months == 0 && day >= 0) ||
          (months > 0 && months < 24) ||
          (months == 24 && day == 0)
        ) {
          // 0-2岁 包含0岁和2岁
          this.plans = [
            {
              planCode: "ZKF110001u",
            },
          ];
          this.sumPremium = "800.00";
          this.indate = "6"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 24 && day > 0) ||
          (months > 24 && months < 36) ||
          (months == 36 && day == 0)
        ) {
          // 2-3岁 包含3岁
          this.plans = [
            {
              planCode: "ZKF110001x",
            },
          ];
          this.sumPremium = "750.00";
          this.indate = "5"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 36 && day > 0) ||
          (months > 36 && months < 48) ||
          (months == 48 && day == 0)
        ) {
          //3-4岁 包含4岁
          this.plans = [
            {
              planCode: "ZKF110002a",
            },
          ];
          this.sumPremium = "600.00";
          this.indate = "4"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 48 && day > 0) ||
          (months > 48 && months < 60) ||
          (months == 60 && day == 0)
        ) {
          // 4-5岁 包含5岁
          this.plans = [
            {
              planCode: "ZKF110002d",
            },
          ];
          this.sumPremium = "450.00";
          this.indate = "3"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 60 && day > 0) ||
          (months > 60 && months < 72) ||
          (months == 72 && day == 0)
        ) {
          // 5-岁 包含6岁
          this.plans = [
            {
              planCode: "ZKF110002g",
            },
          ];
          this.sumPremium = "300.00";
          this.indate = "2"; // 保障期限
          this.isXhrSend = true;
        } else if (
          (months == 72 && day > 0) ||
          (months > 72 && months < 156) ||
          (months == 156 && day == 0)
        ) {
          // 6-13岁 包含13岁
          this.plans = [
            {
              planCode: "ZKF110002j",
            },
          ];
          this.sumPremium = "150.00";
          this.indate = "1"; // 保障期限
          this.isXhrSend = true;
        } else {
          // 小于0岁 或 大于13 超出范围
          this.$toast("被监护人年龄超出范围");
          this.isXhrSend = false;
        }
        this.plans[0].groupNo = "3";
      }
    },
    // 获取*年后的时间
    getLastDate(i) {
      if (i != "- -") {
        let date = moment(new Date().getTime() + 24 * 60 * 60 * 1000).format(
          "YYYY-MM-DD"
        );
        //获取一年后的日期
        var d2 = new Date(date);
        d2.setFullYear(d2.getFullYear() + parseInt(i));
        d2.setDate(d2.getDate() - 1);
        this.futureDate = moment(d2).format("YYYY年MM月DD日");
        this.endDate = moment(d2).format("YYYY-MM-DD");
      }
    },
    //权益-查看详情
    rightsAndInterests(item) {
      var price;
      if (item.code == "ZZJ689") {
        price = "150万";
      } else {
        price = "300万";
      }
      this.$router.push({
        path: "/rightsAndInterests",
        query: {
          id: item.id,
          price: price,
        },
      });
    },
    //费率表、保险条款
    fileList(type) {
      this.$router.push({
        path: "/fileList",
        query: {
          type: type,
        },
      });
    },

    //客户告知书
    customerNotification() {
      this.$router.push({
        path: "/pdf",
        query: {
          type: "native",
        },
      });
    },

    //跳转投保须知
    insuranceNotice() {
      this.$router.push({
        path: "/insuranceNotice",
      });
    },
    //根据身份证号码获取出生日期
    getBirthdayFromIdCard(idCard) {
      var birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      return birthday;
    },
    // 根据出生日期获取年龄
    getAgeByBirthday(strAge) {
      if (strAge) {
        var birArr = strAge.split("-");
        var birYear = birArr[0];
        var birMonth = birArr[1];
        var birDay = birArr[2];

        d = new Date();
        var nowYear = d.getFullYear();
        var nowMonth = d.getMonth() + 1; //记得加1
        var nowDay = d.getDate();
        var returnAge;

        if (birArr == null) {
          return false;
        }
        var d = new Date(birYear, birMonth - 1, birDay);
        if (
          d.getFullYear() == birYear &&
          d.getMonth() + 1 == birMonth &&
          d.getDate() == birDay
        ) {
          if (nowYear == birYear) {
            returnAge = 1; //
          } else {
            var ageDiff = nowYear - birYear; //
            if (ageDiff > 0) {
              if (nowMonth == birMonth) {
                var dayDiff = nowDay - birDay; //
                if (dayDiff < 0) {
                  returnAge = ageDiff - 1;
                } else {
                  returnAge = ageDiff;
                }
              } else {
                var monthDiff = nowMonth - birMonth; //
                if (monthDiff < 0) {
                  returnAge = ageDiff - 1;
                } else {
                  returnAge = ageDiff;
                }
              }
            } else {
              return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
            }
          }
          return returnAge;
        } else {
          return "输入的日期格式错误！";
        }
      }
    },
    //根据身份证号码获取性别
    getSex(UUserCard) {
      if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
        return "1"; //男
      } else {
        return "2"; //女
      }
    },
    EditTimeFn(data) {
      if (data != "now") {
        var newTime = new Date(data);
      } else {
        var newTime = new Date();
      }
      // data的格式可以是 2022-02-22 也可以是别的格式

      var y, M, d, h, m, s;
      y = newTime.getFullYear();
      M = newTime.getMonth() + 1;
      d = newTime.getDate();
      // h = newTime .getHours();
      // m = newTime .getMinutes();
      // s = newTime .getSeconds();
      M = M < 10 ? "0" + M : M;
      d = d < 10 ? "0" + d : d;
      // h = h < 10 ? '0' + h : h;
      // m = m < 10 ? '0' + m : m;
      // s = s < 10 ? '0' + s : s;
      return parseInt(`${y}${M}${d}`);
    },
    //切换保障计划
    changeResponsibility(index, item) {
      if (item) {
        this.planId = item.id;
        this.planName = item.securityPlanName;
      }
      this.responsibilityActive = index;
      this.fromData.inputFactors.choiceType = index + 1;
      if (this.insuredFrom.birthday) {
        if (index == 0) {
          this.getAge(this.insuredFrom.birthday, 0);
        } else if (index == 1) {
          this.getAge(this.insuredFrom.birthday, 1);
        } else {
          this.getAge(this.insuredFrom.birthday, 2);
        }
      }
    },

    // 监听投保人身份证件号码
    wathcApplicantCertNo() {
      if (this.fromData.applicant.certNo.length == 18) {
        this.fromData.applicant.birthday = this.getBirthdayFromIdCard(
          this.fromData.applicant.certNo
        );
        this.fromData.applicant.sex = this.getSex(
          this.fromData.applicant.certNo
        );
        if (this.getSex(this.fromData.applicant.certNo) == "1") {
          this.sexApplicantValue = "男";
        } else {
          this.sexApplicantValue = "女";
        }
      }
    },
    // 投保人选择性别
    changeSexApplicant() {
      this.holderSexApplicant = true;
    },
    // 投保人选择性别件类型回调
    callbackSexApplicant(value, id) {
      this.sexApplicantValue = value;
      this.fromData.applicant.sex = id;
    },
    // 被保人选择性别
    changeSexInsured() {
      this.holderSexInsured = true;
    },
    // 被保人选择身份证件类型回调
    callbackSexInsured(value, id) {
      this.sexInsuredValue = value;
      this.insuredFrom.sex = id;
    },
    //投保
    async rbInsure() {
      if (!this.fromData.applicant.name) {
        this.$toast("请填写投保人姓名");
      } else if (!this.fromData.applicant.certNo) {
        this.$toast("请填写投保人证件号码");
      } else if (!validIdCard(this.fromData.applicant.certNo)) {
        this.$toast("请填写正确的投保人身份证号码");
      } else if (!this.fromData.applicant.mobile) {
        this.$toast("请填写投保人手机号码");
      } else if (!validPhone(this.fromData.applicant.mobile)) {
        this.$toast("请填写正确的投保人手机号码");
      } else if (!this.fromData.applicant.sex) {
        this.$toast("请选择投保人性别");
      } else if (!this.insuredFrom.name) {
        this.$toast("请填写被监护人姓名");
      } else if (!this.insuredFrom.sex) {
        this.$toast("请选择被监护人性别");
      } else if (!this.insuredFrom.birthday) {
        this.$toast("请选择被监护人出生日期");
      } else if (!this.selectedHospitalItem?.id) {
        this.$toast("请选择医院");
      } else if (!this.checkFile) {
        this.$toast("请先阅读相关文件");
      } else {
        let appliBirthday = this.getBirthdayFromIdCard(
          this.fromData.applicant.certNo
        );
        let insuredBirthday = this.getBirthdayFromIdCard(
          this.insuredFrom.certNo
        );
        let params = {
          insurancePlanId: this.planId, // 保障计划id
          insurancePlanName: this.planName, // 保障计划名称
          guaranteeNum: this.indate, // 保障期限
          openId: sessionStorage.getItem("openId"),
          policyInfos: [
            {
              applicant: {
                appliIdNumber: this.fromData.applicant.certNo,
                appliBirthday: appliBirthday,
                appliIdType: "01",
                appliMobile: this.fromData.applicant.mobile,
                appliName: this.fromData.applicant.name,
                appliSex: this.fromData.applicant.sex,
              },
              endDate: this.endDate,
              endHour: "24",
              insureds: [
                {
                  insuredIdNumber: this.fromData.applicant.certNo,
                  insuredBirthday: appliBirthday,
                  insuredIdType: "01",
                  insuredName: this.fromData.applicant.name,
                  insuredSeqNo: "1",
                  insuredSex: this.fromData.applicant.sex,
                  planGroupNo: this.plans[0].groupNo,
                  quantity: "1",
                },
              ],
              guardians: [
                {
                  guardianName: this.insuredFrom.name,
                  guardianSex: this.insuredFrom.sex,
                  guardianBirthday: this.insuredFrom.birthday,
                },
              ],
              plans: this.plans,
              riskCode: "ZKF",
              serialNo: "1",
              startDate: moment(
                new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              ).format("YYYY-MM-DD"),
              startHour: "0",
              sumAmount: this.sumAmount,
              sumPremium: this.sumPremium,
            },
          ],
          productId: sessionStorage.getItem("productId"),
          channelId: localStorage.getItem("appId") || "wx2f80f772533e490f",
          hospitalId: this.selectedHospitalItem?.id,
          traceableId: sessionStorage.getItem("recordId"), // 回溯id
        };
        if (this.isXhrSend) {
          if (this.isDisabled) {
            this.isDisabled = false;
            insure(params).then((res) => {
              console.log(res);
              if (res.success) {
                this.isDisabled = true;
                window.location.href = res.result;
              } else {
                this.isDisabled = true;
                this.$toast(res.message);
              }
            });
          } else {
            this.$toast("请求中，请稍后");
          }
        } else {
          this.$toast("被监护人年龄超出范围");
        }
      }
    },
    async onShowHospitalPicker() {
      this.showHospitalPicker = true;
      this.hospitalLoading = true;
      try {
        const res = await queryHospitals();
        if (res.success) {
          this.hospitals = res.result || [];
        } else {
          this.hospitals = [];
        }
      } catch (error) {
        this.hospitals = [];
        this.showHospitalPicker = false;
      } finally {
        this.hospitalLoading = false;
      }
    },
    onConfirmHospital(value, indexs) {
      if (!indexs.length) {
        this.showHospitalPicker = false;
        return;
      }
      const target = this.findHospitalByPath(this.hospitals, indexs);
      this.selectedHospitalItem = target || null;
      this.showHospitalPicker = false;
    },
    findHospitalByPath(data, path) {
      if (!data || path.length === 0) {
        return null; // 如果数据不存在或路径为空，返回null
      }

      const currentIndex = path.shift(); // 取出当前要访问的索引

      if (currentIndex >= data.length || currentIndex < 0) {
        return null; // 如果索引超出范围，返回null
      }

      if (path.length === 0) {
        return data[currentIndex]; // 如果已经遍历完路径，返回当前数据项
      }

      return this.findHospitalByPath(data[currentIndex].children, path); // 递归调用，继续遍历子节点
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-radio-group {
  margin-left: 15px;
}
/deep/ .van-radio__icon--checked .van-icon {
  background-color: $primary-color;
  border-color: $primary-color;
}
/deep/ .van-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.insure-date {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
}
.responsibility {
  width: 100%;
  margin-top: 15px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .responsibility-bg-active {
    background: url("../../../../assets/responsibility-active.png") no-repeat;
    background-size: 100% 100%;
  }
  .responsibility-bg {
    background: url("../../../../assets/responsibility-noactive.png") no-repeat;
    background-size: 100% 100%;
  }
  .item {
    width: 100px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      span {
        display: block;
      }
    }
    .responsibilityColor-active {
      color: $primary-color;
    }
    .responsibilityColor {
      color: #aaaaaa;
    }
  }
  .msg {
    margin-top: 11px;
    width: 100%;
    height: 113px;
    padding: 25px 18px 0;
    p {
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
      line-height: 22px;
    }
    div {
      display: flex;
      margin-top: 11px;
      img {
        width: 13px;
        height: 13px !important;
        margin-top: 3px;
      }
      p {
        flex: 1;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-left: 8px;
      }
    }
  }
  .msg1 {
    background: url("../../../../assets/range-bg1.png") no-repeat;
    background-size: 100%;
  }
  .msg2 {
    background: url("../../../../assets/range-bg2.png") no-repeat;
    background-size: 100%;
  }
}
input:disabled {
  background-color: #fff; //修改默认灰色样式
  color: #000;
  opacity: 1; //默认的不透明级别为0.3
  -webkit-text-fill-color: #000; //字体颜色安卓与IOS适配
  -webkit-opacity: 1; ///不透明级别安卓与IOS适配
}
// input{
//   color: #000 !important;
// }

.insure {
  padding: 10px;
  /deep/ .van-dialog__confirm {
    .van-button__text {
      color: $primary-color;
    }
  }
  /deep/ .van-cell {
    padding: 5px 0;
  }
  /deep/ .van-cell__title {
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 44px;
    margin-right: 0;
  }

  /deep/ .van-cell__value {
    width: 100%;
    flex: 1;
    margin-left: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    .van-field__body {
      height: 100%;
    }
    input::-webkit-input-placeholder {
      color: #999;
    }
  }
  /deep/ .van-dialog {
    .van-cell {
      padding: 20px;
    }
  }
  .applicantContent {
    padding-bottom: 110px;
    .applicant-title {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .titleNum {
        width: 18px;
        height: 18px;
        background: $primary-color;
        border-radius: 2px;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
      }
      .titleName {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-left: 5px;
      }
    }
    .form {
      width: 100%;
      margin-top: 5px;
      .uni-form-item {
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        .tit {
          width: 100px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 44px;
        }
        .uni-input {
          width: 100%;
          flex: 1;
          margin-left: 16px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          padding: 0;
          .input {
            color: #000;
          }
          .inputno {
            color: #999;
          }
        }
        .arrow {
          width: 10px;
          font-size: 16px;
          margin-right: 17px;
        }
      }
    }
  }
}
.beneficiaryType {
  display: flex;
  margin-top: 34px;

  text {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 72px;
    border-radius: 42px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;

    margin-right: 40px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .beneficiaryTit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    .line1 {
      float: left;
    }
    .line2 {
      float: left;
    }
  }
  .delete {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #999999;
    line-height: 5px;
  }
}
.add {
  width: 124px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid $primary-color;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
  line-height: 36px;
  margin: 0 auto;
  margin-top: 20px;
}
.footer {
  width: 100%;
  height: 110px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  border-top: 1px solid #e5e5e5;
  .handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .price {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
      line-height: 16px;
      padding-left: 12px;
      .num {
        font-size: 20px;
      }
    }
    .btn {
      margin-right: 12px;
      width: 116px;
      height: 45px;
      line-height: 45px;
      background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
      border-radius: 48px;
      color: #ffffff;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin-top: 11px;
    img {
      width: 14px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      line-height: 13px;
    }
    .list {
      display: flex;
      align-items: center;
      p {
        padding: 0 10px;
        font-size: 13px;
        font-weight: 400;
        color: #569cff;
        line-height: 13px;
        border-right: 1px solid #e5e5e5;
      }
      p:last-child {
        border-right: none;
      }
    }
  }
}

.beneficiaryType {
  display: flex;
  margin-top: 34px;
  div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    margin-right: 10px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
    box-sizing: border-box;
  }
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  .item:first-child {
    margin-top: 0;
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .name {
      height: 40px;
      border-radius: 24px;
      margin-right: 19px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }
    .equity {
      // width: 82px;
      // height: 30px;

      padding: 7px 8px;
      background: #fff7f4;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: $primary-color;
        line-height: 17px;
        margin-left: 4px;
      }
    }
  }

  // div {
  //   height: 40px;
  //   border-radius: 24px;
  //   margin-right: 19px;
  //   font-size: 14px;
  //   font-weight: 400;
  //   line-height: 14px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0 10px;
  // }

  .active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #ffffff;
    // padding: 0 25px;
  }

  .no-active {
    // padding: 0 25px;
    border: 2px solid $primary-color;
    box-sizing: border-box;
    color: $primary-color;
  }
}
</style>
