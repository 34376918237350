<template>
  <van-action-sheet v-model="show" @close="closeAction"
                    >
    <div class="content">
      <van-datetime-picker v-model="currentDate"
                           type="date"
                           title="选择年月日"
                           :min-date="minDate"
                           :max-date="maxDate"
                           @confirm="confirm"
                           @cancel="cancel" />
    </div>
  </van-action-sheet>
</template>
<script>
export default {
  components: {},
  props: {
    isShow: Boolean,
    inValue: String,
    isShowStr: String,
    typeDate:String,
  },
  watch: {
    isShow(newVlue) {
      this.show = newVlue
    },
    // typeDate(newVlue) {
    //   console.log(newVlue)
    //   this.typeName = newVlue
    // },
  },
  data() {
    return {
      show: false,
      minDate: "",
      maxDate: new Date(2120, 10, 1),
      currentDate: "",
    }
  },

  mounted() {
      // 获取当前时间
      var myDate = new Date();
      // 2.获取时间中的年月日时分秒
      myDate.getYear(); // 获取当前年份(2位)
      myDate.getFullYear(); // 获取完整的年份(4位,1970-????)
      myDate.getMonth(); // 获取当前月份(0-11,0代表1月)
      myDate.getDate(); // 获取当前日(1-31)
      myDate.getDay(); // 获取当前星期X(0-6,0代表星期天)
      myDate.getTime(); // 获取当前时间(从1970.1.1开始的毫秒数)
      myDate.getHours(); // 获取当前小时数(0-23)
      myDate.getMinutes(); // 获取当前分钟数(0-59)
      myDate.getSeconds(); // 获取当前秒数(0-59)
      myDate.getMilliseconds(); // 获取当前毫秒数(0-999)
      myDate.toLocaleDateString(); // 获取当前日期
      // var mytime = myDate.toLocaleTimeString(); // 获取当前时间
      myDate.toLocaleString(); // 获取日期与时间
      // 3.时间的格式化

      Date.prototype.Format = function (fmt) {
        // author: meizz
        var o = {
          "M+": this.getMonth() + 1, // 月份
          "d+": this.getDate(), // 日
          "h+": this.getHours(), // 小时
          "m+": this.getMinutes(), // 分
          "s+": this.getSeconds(), // 秒
          "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
          S: this.getMilliseconds(), // 毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };
      this.time1  = new Date().Format("yyyy-MM-dd");

      this.currentDate=  new Date( this.time1 ) 
      
      if(this.typeDate == 'tbStart' || this.typeDate == 'bbStart'||this.typeDate == 'applicantBirthaday'||this.typeDate == 'insuredBirthaday'){
        this.minDate=  new Date( '1900-01-01' );
        this.maxDate = new Date( this.time1 );
      }else{
        this.minDate=  new Date( this.time1 ) ;
        this.maxDate = new Date('3000-01-01');
      }
  },
  onLoad() {},
  methods: {
    // 日期格式转化
    timeFormat(time) {
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()
      return year + '-' + month + '-' + day 
    },
    // 关闭弹框
    closeAction(){
      this.$parent[this.isShowStr] = false
      this.show = false;
      this.$emit('cancelDate',this.typeDate)
    },
    // 提交
    confirm(value) {
      this.$parent[this.isShowStr]  = false
      this.$parent[this.inValue] = this.timeFormat(value)
      this.show = false
      this.$emit('callbackDate',this.typeDate, new Date(this.currentDate).Format("yyyy-MM-dd"))
    },
    // 取消
    cancel() {
      this.$parent[this.isShowStr]  = false
      this.show = false
      console.log(this.typeName)
      this.$emit('cancelDate',this.typeDate)
    },
  },
}
</script>


<style scoped lang="scss">
.content {
  padding: 16px 16px 16px;
}
</style>
