let educationList = [{
    text: "博士",
    id: "1",
},
{
    text: "硕士",
    id: "2",
},
{
    text: "本科",
    id: "3",
},
{
    text: "大专",
    id: "4",
},
{
    text: "中专",
    id: "5",
},
{
    text: "高中及同等学历",
    id: "6",
},
{
    text: "初中及同等学历",
    id: "7",
},
{
    text: "初中以下学历",
    id: "8",
},
{
    text: "其他",
    id: "9",
},
]

let politicalList = [{
    text: "中共党员",
    id: "1",
},
{
    text: "中共预备党员",
    id: "2",
},
{
    text: "共青团员",
    id: "3",
},
{
    text: "民革会员",
    id: "4",
},
{
    text: "民盟盟员",
    id: "5",
},
{
    text: "民建会员",
    id: "6",
},
{
    text: "民进会员",
    id: "7",
},
{
    text: "农工党党员",
    id: "8",
},
{
    text: "致公党党员",
    id: "9",
}, {
    text: "九三学社社员",
    id: "10",
},
{
    text: "台盟盟员",
    id: "11",
}, {
    text: "无党派人士",
    id: "12",
},
{
    text: "群众",
    id: "13",
}, {
    text: "其他",
    id: "14",
},

]



let relationshipList = [{
    text: "本人",
    id: "00",
},
{
    text: "妻子",
    id: "01",
},
{
    text: "丈夫",
    id: "02",
},
{
    text: "儿女",
    id: "03",
},
{
    text: "父亲",
    id: "04",
},
{
    text: "母亲",
    id: "05",
},
{
    text: "岳父",
    id: "06",
},
{
    text: "岳母",
    id: "07",
},
{
    text: "其他个人",
    id: "31",
},
]


let cardTypeList = [{
  text: "身份证",
  id: "0",
},
{
  text: "护照",
  id: "1",
},
]

let sexList = [{
  text: "男",
  id: "1",
},
{
  text: "女",
  id: "2",
},{
    text:'未知',
    id:'9'
}
]


let nationalityList = [{
  text: "港澳台人士",
  id: "HK",
},
{
  text: "中国大陆",
  id: "ML",
},
{
  text: "外籍人士",
  id: "OS",
},
{
  text: "其它",
  id: "OT",
},
]




export {
    educationList,
    politicalList,
    relationshipList,
    cardTypeList,
    sexList,
    nationalityList
}